import React, { useEffect, useState } from "react";
import styles from "./QThree.module.css";
import PopupCard from "./PopupCard";

import correct from "../../assets/correct.svg";
import incorrect from "../../assets/incorrect.svg";

const QThree = ({ onNext, load }) => {
  const choices = [
    { id: 0, txt: "" },
    { id: 1, txt: "نمكن موظفينا ونعمل لإثراء  تجاربكم" },
    { id: 2, txt: "ندير توقعاتكم" },
    { id: 3, txt: "طلباتكم" },
    { id: 4, txt: "نقدركم" },
    { id: 5, txt: "تطلعاتكم" },
    { id: 6, txt: "نسهل معاملتكم" },
    { id: 7, txt: "ننصت لملاحظتكم ونعالج مشكلاتكم" },
    { id: 8, txt: "الموقع الإلكتروني" },
    { id: 9, txt: "نحن نستمع" },
  ];

  const btnStyle = {
    textAlign: "center",
    fontFamily: "HRSD",
    fontWeight: "bold",
    color: "#fff",
    // backgroundColor: "rgba(255, 255, 255, 0.5)",
    whiteSpace: "pre-wrap",
    padding: "1%",
    position: "absolute",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "2.5vh",
  };
  const btn1 = {
    position: "absolute",
    width: "calc(120.92/820*50vw)",
    height: "calc(120.92/820*50vw)",
    marginTop: "calc(130/820*50vw)",
    marginLeft: "calc(630/820*50vw)",
  };
  const btn2 = {
    position: "absolute",
    width: "calc(182.1/820*50vw)",
    height: "calc(182.1/820*50vw)",
    marginTop: "calc(100/820*50vw)",
    marginLeft: "calc(345/820*50vw)",
  };
  const btn3 = {
    position: "absolute",
    width: "calc(134.23/820*50vw)",
    height: "calc(134.23/820*50vw)",
    marginLeft: "calc(177/820*50vw)",
    marginTop: "calc(2/820*50vw)",
  };
  const btn4 = {
    position: "absolute",
    width: "calc(89.53/820*50vw)",
    height: "calc(89.53/820*50vw)",
    marginTop: "calc(300/820*50vw)",
    marginLeft: "calc(220/820*50vw)",
  };
  const btn5 = {
    position: "absolute",
    width: "calc(102/820*50vw)",
    height: "calc(102/820*50vw)",
    marginTop: "calc(117/820*50vw)",
    marginLeft: "calc(63/820*50vw)",
  };

  const correctAns = [6, 7, 1, 4, 2];
  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(choices.length - 5).fill(0)
  );
  const [image, setImage] = useState(false);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const allNonZero = selectedAnswers.every((value) => value !== 0);
    if (allNonZero) {
      const isEqual =
        JSON.stringify(correctAns) === JSON.stringify(selectedAnswers);
      if (isEqual) {
        setImage(true);
        toggleCorrection();
        setTimeout(() => {
          onNext();
        }, 300);
      } else {
        setImage(false);
        toggleCorrection();
        setSelectedAnswers((prevSelectedAnswers) =>
          prevSelectedAnswers.map((value, index) =>
            correctAns[index] === value ? value : 0
          )
        );
      }
    }
  }, [selectedAnswers]);
  const toggleCorrection = () => {
    setIsActive(true);

    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };

  const handleSelectChange = (id, index) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[index] = id;
    setSelectedAnswers(newSelectedAnswers);
  };

  return (
    <>
      <div
        className={`${styles.feedbackContainer} ${
          isActive ? styles.active : ""
        }`}
      >
        <img src={image ? correct : incorrect} alt="Feedback" />
      </div>
      <div className={styles.container}>
        <h2 className={styles.qTxt}>
          نموذج للغاية والالتزمات
          <br />
          <span>تجاه المستفيــــــد</span>
        </h2>
        {load ? (
          <div className={styles.ansContainer}>
            <PopupCard
              title={"تذكر مكونات نموذج الغايات"}
              btnStyle={{ ...btnStyle, ...btn1 }}
              onSelect={(id) => handleSelectChange(id, 0)}
              choices={choices}
              txt={choices[selectedAnswers[0]].txt}
            />
            <PopupCard
              title={"تذكر مكونات نموذج الغايات"}
              btnStyle={{ ...btnStyle, ...btn2 }}
              onSelect={(id) => handleSelectChange(id, 1)}
              choices={choices}
              txt={choices[selectedAnswers[1]].txt}
            />
            <PopupCard
              title={"تذكر مكونات نموذج الغايات"}
              btnStyle={{ ...btnStyle, ...btn3 }}
              onSelect={(id) => handleSelectChange(id, 2)}
              choices={choices}
              txt={choices[selectedAnswers[2]].txt}
            />
            <PopupCard
              title={"تذكر مكونات نموذج الغايات"}
              btnStyle={{ ...btnStyle, ...btn4 }}
              onSelect={(id) => handleSelectChange(id, 3)}
              choices={choices}
              txt={choices[selectedAnswers[3]].txt}
            />
            <PopupCard
              title={"تذكر مكونات نموذج الغايات"}
              btnStyle={{ ...btnStyle, ...btn5 }}
              onSelect={(id) => handleSelectChange(id, 4)}
              choices={choices}
              txt={choices[selectedAnswers[4]].txt}
            />
          </div>
        ) : (
          <div className={styles.prvContainer} />
        )}
      </div>
    </>
  );
};

export default QThree;
