import React, { useEffect, useState } from "react";
import styles from "./QTwo.module.css";
import PopupCard from "./PopupCard";

import correct from "../../assets/correct.svg";
import incorrect from "../../assets/incorrect.svg";

const QTwo = ({ onNext, load }) => {
  const choices = [
    { id: 0, txt: "" },
    { id: 1, txt: "الخطابات والمعاريض" },
    { id: 2, txt: "مراكز الاتصال" },
    { id: 3, txt: "شبكات التواصل الاجتماعي" },
    { id: 4, txt: "الزيارات المكتبية" },
    { id: 5, txt: "مراكز الخدمة" },
    { id: 6, txt: "البريد الاكتزوني" },
    { id: 7, txt: "الموقع الاكتزوني" },
    { id: 8, txt: "تطبيق الهاتف" },
    { id: 9, txt: "الرسائل النصية" },
    { id: 10, txt: "زيارة المدونات" },
  ];

  const btnStyle = {
    position: "relative",
    textAlign: "center",
    fontFamily: "HRSD",
    fontWeight: "bold",
    color: "#fff",
    // backgroundColor: "rgba(255, 255, 255, 0.5)",
    whiteSpace: "pre-wrap",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "2vh",
  };
  const btn1 = {
    position: "absolute",
    width: "calc(149/820*50vw)",
    height: "calc(149/820*50vw)",
    borderRadius: "100%",
    marginLeft: "calc(474/820*50vw)",
  };
  const btn2 = {
    position: "absolute",
    width: "calc(112/820*50vw)",
    height: "calc(112/820*50vw)",
    borderRadius: "100%",
    marginTop: "calc(156/820*50vw)",
    marginLeft: "calc(708/820*50vw)",
  };
  const btn3 = {
    position: "absolute",
    width: "calc(143/820*50vw)",
    height: "calc(143/820*50vw)",
    borderRadius: "100%",
    marginTop: "calc(399/820*50vw)",
    marginLeft: "calc(596/820*50vw)",
  };
  const btn4 = {
    position: "absolute",
    width: "calc(128/820*50vw)",
    height: "calc(128/820*50vw)",
    borderRadius: "100%",
    marginTop: "calc(503/820*50vw)",
    marginLeft: "calc(312/820*50vw)",
  };
  const btn5 = {
    position: "absolute",
    width: "calc(95/820*50vw)",
    height: "calc(95/820*50vw)",
    borderRadius: "100%",
    marginTop: "calc(463/820*50vw)",
    marginLeft: "calc(108/820*50vw)",
  };
  const btn6 = {
    position: "absolute",
    width: "calc(124/820*50vw)",
    height: "calc(129/820*50vw)",
    borderRadius: "100%",
    marginLeft: "calc(2/820*50vw)",
    marginTop: "calc(179/820*50vw)",
  };

  const correctAns = [3, 2, 5, 7, 8, 6];
  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(choices.length - 5).fill(0)
  );
  const [image, setImage] = useState(false);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const allNonZero = selectedAnswers.every((value) => value !== 0);
    if (allNonZero) {
      const isEqual =
        JSON.stringify(correctAns) === JSON.stringify(selectedAnswers);
      if (isEqual) {
        setImage(true);
        toggleCorrection();
        setTimeout(() => {
          onNext();
        }, 300);
      } else {
        setImage(false);
        toggleCorrection();
        setSelectedAnswers((prevSelectedAnswers) =>
          prevSelectedAnswers.map((value, index) =>
            correctAns[index] === value ? value : 0
          )
        );
      }
    }
  }, [selectedAnswers]);
  const toggleCorrection = () => {
    setIsActive(true);

    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };

  const handleSelectChange = (id, index) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[index] = id;
    setSelectedAnswers(newSelectedAnswers);
  };

  return (
    <>
      <div
        className={`${styles.feedbackContainer} ${
          isActive ? styles.active : ""
        }`}
      >
        <img src={image ? correct : incorrect} alt="Feedback" />
      </div>
      <div className={styles.container}>
        <h2 className={styles.qTxt}>
          {" "}
          قنوات التواصل
          <br />
          <span>لخدمات المستفيد</span>
        </h2>
        {load ? (
          <div className={styles.aContainer}>
            <PopupCard
              title={"قم بتسمية قنوات التواصل لخدمة المستفيد"}
              btnStyle={{ ...btnStyle, ...btn1 }}
              choices={choices}
              onSelect={(id) => handleSelectChange(id, 0)}
              txt={choices[selectedAnswers[0]].txt}
            />
            <PopupCard
              title={"قم بتسمية قنوات التواصل لخدمة المستفيد"}
              btnStyle={{ ...btnStyle, ...btn2 }}
              choices={choices}
              onSelect={(id) => handleSelectChange(id, 1)}
              txt={choices[selectedAnswers[1]].txt}
            />
            <PopupCard
              title={"قم بتسمية قنوات التواصل لخدمة المستفيد"}
              btnStyle={{ ...btnStyle, ...btn3 }}
              choices={choices}
              onSelect={(id) => handleSelectChange(id, 2)}
              txt={choices[selectedAnswers[2]].txt}
            />
            <PopupCard
              title={"قم بتسمية قنوات التواصل لخدمة المستفيد"}
              btnStyle={{ ...btnStyle, ...btn4 }}
              choices={choices}
              onSelect={(id) => handleSelectChange(id, 3)}
              txt={choices[selectedAnswers[3]].txt}
            />
            <PopupCard
              title={"قم بتسمية قنوات التواصل لخدمة المستفيد"}
              btnStyle={{ ...btnStyle, ...btn5 }}
              choices={choices}
              onSelect={(id) => handleSelectChange(id, 4)}
              txt={choices[selectedAnswers[4]].txt}
            />
            <PopupCard
              title={"قم بتسمية قنوات التواصل لخدمة المستفيد"}
              btnStyle={{ ...btnStyle, ...btn6 }}
              choices={choices}
              onSelect={(id) => handleSelectChange(id, 5)}
              txt={choices[selectedAnswers[5]].txt}
            />
          </div>
        ) : (
          <div className={styles.prvContainer} />
        )}
      </div>
    </>
  );
};

export default QTwo;
