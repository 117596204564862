import React, { useEffect, useState } from "react";
import styles from "./SnapshotPage.module.css";
import cup from "../assets/new/cup.svg";
import share from "../assets/new/shareBtn.svg";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Components/Header";
import useFetch from "../hooks/useFetch";
import axios from "axios";

const SnapshotPage = () => {
  const navigate = useNavigate();
  const [ranks, setRanks] = useState([]);
  const [userRank, setUserRank] = useState({});
  const { gameId, userId } = useParams();

  const formatTime = (t) => {
    const minutes = Math.floor(t / 60);
    const remainingSeconds = t % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    axios
      .get("https://cx-test-b63e7-default-rtdb.firebaseio.com/api/deps.json")
      .then((response) => {
        if (gameId === "2") {
          const sortedData = Object.values(response.data)
            .filter((item) => item["score-2"] !== 1e39)
            .sort((a, b) => a[`score-2`] - b[`score-2`])
            .map((item) => ({
              ...item,
              "score-2": formatTime(item["score-2"]),
            }));
          setRanks(sortedData);
        } else {
          const sortedData = Object.values(response.data)
            .filter((item) => item[`score-${gameId}`] !== 0)
            .sort((a, b) => b[`score-${gameId}`] - a[`score-${gameId}`]);
          setRanks(sortedData);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (ranks.length !== 0) {
      axios
        .get("https://cx-test-b63e7-default-rtdb.firebaseio.com/api/users.json")
        .then((response) => {
          if (response.data) {
            const usersArray = Object.keys(response.data).map((id) => ({
              id,
              ...response.data[id],
            }));

            const user = usersArray.filter((user) => user.id === userId)[0];
            const depId = user.department_id;

            const userIndex = ranks.findIndex((rank) => rank.id === depId);
            const name = user.first_name + " " + user.last_name;
            const points = user[`score-${gameId}`];

            if (userIndex !== -1) {
              const rank = {
                name,
                rank: ranks.findIndex((rank) => rank.id === depId) + 1,
                points: gameId === "2" ? formatTime(points) : points,
              };
              setUserRank(rank);
            }
          }
        })
        .catch((error) => {});
    }
  }, [ranks]);

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.ranks}>
        {ranks.slice(0, 4).map((rank, i) => (
          <div className={styles.place} key={rank.id}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "rotate(-2deg)",
              }}
            >
              <p>{rank.name}</p>
            </div>
            <div className={styles.ranksH2Container}>
              <h2 className={styles.ranksH2}>
                {gameId === "2" ? "دقيقة" : "نقطة"}
              </h2>
              <h2 className={styles.ranksH2}>{rank[`score-${gameId}`]}</h2>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.details}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 className={styles.name}>{userRank.name}</h3>
          <div className={styles.myResult}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0.2vh",
                  transform: "rotate(-2deg)",
                }}
              >
                <h2>{gameId === "2" ? "دقيقة" : "نقطة"}</h2>
                <h2 style={{ color: "#2bb374" }}>{userRank.points}</h2>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "-9vh",
                  transform: "rotate(-2deg)",
                }}
              >
                <h3>
                  فريقك في المرتبة{" "}
                  <span className={styles.span2}>{`${userRank.rank}`}</span>
                </h3>
              </div>
            </div>
            <img src={cup} />
          </div>
        </div>
        <div
          className={styles.shareContainer}
          onClick={() => navigate(`/share-result/${gameId}`)}
        >
          <p style={{ color: "white" }}>
            {new Date()
              .toLocaleDateString("en-US", {
                timeZone: "Asia/Riyadh",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
              .replace(",", "")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SnapshotPage;
