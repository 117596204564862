import React, { useEffect } from "react";
import GameCard from "../Components/GameCard";
import styles from "./SelectGame.module.css";
import Header from "../Components/Header";

import q1 from "../assets/new/October/Quiz 1.png";
import q2 from "../assets/new/October/Quiz 2.png";
import q3 from "../assets/new/October/Quiz 3.png";

const SelectGame = () => {
  const cardsData = [
    {
      title: "اللعبة ١",
      image: q1,
      linkTo: "/splash/1",
      isActive: localStorage.getItem("quiz-1") ? false : true,
    },
    {
      title: "اللعبة ٢",
      image: q2,
      linkTo: "/splash/2",
      isActive: localStorage.getItem("quiz-2") ? false : true,
    },
    {
      title: "اللعبة ٣",
      image: q3,
      linkTo: "/splash/3",
      isActive: localStorage.getItem("quiz-3") ? false : true,
    },
  ];

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.grid}>
          {cardsData.map((card, index) => (
            <div className={styles.gameCard}>
              <GameCard
                isActive={card.isActive}
                key={index}
                title={card.title}
                image={card.image}
                linkTo={card.linkTo}
                height={"35vw"}
                width={"20vw"}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SelectGame;
