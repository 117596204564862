import React, { useState, useRef, useEffect } from "react";
import Header from "../../Components/Header";
import styles from "./Quiz.module.css";
import useFetch from "../../hooks/useFetch";

import a1 from "../../assets/new/October/a1.png";
import a2 from "../../assets/new/October/a2.png";
import a3 from "../../assets/new/October/a3.png";
import a4 from "../../assets/new/October/a4.png";
import a5 from "../../assets/new/October/a5.png";
import a6 from "../../assets/new/October/a6.png";
import a7 from "../../assets/new/October/a7.png";
import a8 from "../../assets/new/October/a8.png";
import Stopwatch from "./Stopwatch";
import { useNavigate } from "react-router-dom";
import TinderCard from "react-tinder-card";
import next from "../../assets/new/next.svg";
import Timer from "../GameOneComponents/Timer";
import ChoiceCard from "./ChoiceCard";

import correct from "../../assets/correct.svg";
import incorrect from "../../assets/incorrect.svg";
import half from "../../assets/new/half.svg";
import axios from "axios";

const Quiz = ({ qData }) => {
  const questions = [
    {
      id: 1,
      questionTxt: `"أوقات الانتظار الطويلة في المكاتب الحكومية" - شكوى المستفيد: "اضطررت إلى الانتظار لساعات في مكتب الخدمة"`,
      img: a1,
      choices: [
        {
          id: 1,
          option: "الاعتذار واغلاق التذكرة ",
          isCorrect: false,
        },
        {
          id: 2,
          option: "توجيه المستفيد لملئ نموذج تقيم الخدمة",
          isCorrect: true,
        },
        {
          id: 3,
          option: "تقديم خصم على الخدمات الحكومية",
          isCorrect: false,
        },
        {
          id: 4,
          option: "تصعيد المشكلة",
          isCorrect: false,
        },
      ],
    },
    {
      id: 2,
      questionTxt: `"الموقع الإلكتروني للخدمة الذي لا يمكن الوصول إليه"  - شكوى المستفيد: "لا أستطيع الوصول إلى المعلومات الحيوية على موقع إحدى الخدمات" `,
      img: a2,
      choices: [
        {
          id: 1,
          option: "اعتذر وأغلق التذكرة",
          isCorrect: false,
        },
        {
          id: 2,
          option: "توفير طرق بديلة للوصول إلى المعلومات",
          isCorrect: true,
        },
        {
          id: 3,
          option: "تقديم خصم على الخدمات الحكومية",
          isCorrect: false,
        },
        {
          id: 4,
          option: "تنفيذ الإجراءات بدًلا عن المستفيد",
          isCorrect: false,
        },
      ],
    },
    {
      id: 3,
      questionTxt: `شكوى المستفيد: "لا أستطيع معرفة كيفية التقديم لإحدى خدمات وزارة الموارد البشرية والتنمية الاجتماعية بشكل صحيح."
      ماذا ستفعل لتساعد المستفيد؟`,
      img: a3,
      choices: [
        {
          id: 1,
          option: "ارسال دليل تفصيلي للتسجيل خطوة",
          isCorrect: false,
        },
        {
          id: 2,
          option: "الاعتذار واغلاق التذكرة",
          isCorrect: true,
        },
        {
          id: 3,
          option: "تنفيذ الإجراءات بدًلا عن المستفيد",
          isCorrect: false,
        },
        {
          id: 4,
          option: "رفع تقرير لإدارة الخدمة",
          isCorrect: true,
        },
      ],
    },
    {
      id: 4,
      questionTxt: `افترض ان المستفيد قدم شكوى! شكوى المستفيد: "لقد تقدمت بطلب "احدى الخدمات التابعة لوزارة الموارد البشرية" لكن لم يصلني تحديث حول حالة الطلب." ماذا ستفعل؟`,
      img: a4,
      choices: [
        {
          id: 1,
          option: "تسريع الطلب دون مزيد من الاستفسارات",
          isCorrect: false,
        },
        {
          id: 2,
          option: "اعتذر وأغلق التذكرة",
          isCorrect: false,
        },
        {
          id: 3,
          option: "التحقق من حالة الطلب وتقديم التحديثات للمستفيد",
          isCorrect: true,
        },
        {
          id: 4,
          option: "تجاهل الطلب",
          isCorrect: false,
        },
      ],
    },
    {
      id: 5,
      questionTxt: `كيف يساهم "الاتصال الاستباقي" في استراتيجية تجربة المستفيد في الخدمات الحكومية؟`,
      img: a5,
      choices: [
        {
          id: 1,
          option: "يقلل من شكاوى المستفيد",
          isCorrect: false,
        },
        {
          id: 2,
          option: "يقلل من أوقات الانتظار المستفيد",
          isCorrect: false,
        },
        {
          id: 3,
          option: "تتوقع احتياجات المستفيد وتوفر المعلومات ذات الصلة",
          isCorrect: true,
        },
        {
          id: 4,
          option: "يركز فقط على دعم ما بعد الخدمة",
          isCorrect: false,
        },
      ],
    },
    {
      id: 6,
      questionTxt: `كيف تحقق انطباعات إيجابية للمستفيد؟`,
      img: a6,
      choices: [
        {
          id: 1,
          option: "تقديم الخدمة بشكل متكامل",
          isCorrect: false,
        },
        {
          id: 2,
          option: "احترام وقت المستفيد",
          isCorrect: false,
        },
        {
          id: 3,
          option: "المرونة في عملية التواصل",
          isCorrect: false,
        },
        {
          id: 4,
          option: "جميع ما سبق",
          isCorrect: true,
        },
      ],
    },
    {
      id: 7,
      questionTxt: `تساهم استراتيجية التمركز حول المستفيد في تقديم خدمات مرضية للمستفيدين؛ مما يؤدي إلى `,
      img: a7,
      choices: [
        {
          id: 1,
          option: "رفع معنويات الموظفين",
          isCorrect: false,
        },
        {
          id: 2,
          option: "اكساب المستفيد الثقة في تجاه المنظومة",
          isCorrect: true,
        },
        {
          id: 3,
          option: "التعرف على اهتمامات المستفيدين",
          isCorrect: false,
        },
        {
          id: 4,
          option: "توفير تكاليف الرد على شكاوى المستفيدين",
          isCorrect: false,
        },
      ],
    },
    {
      id: 8,
      questionTxt: `كيف تساهم استراتيجية التمركز حول المستفيد في تحقيق جودة الخدمات حكومية؟`,
      img: a8,
      choices: [
        {
          id: 1,
          option: "تقديم تجربة استثنائية للمستفيد",
          isCorrect: false,
        },
        {
          id: 2,
          option: "خدمات رقمية متكاملة",
          isCorrect: false,
        },
        {
          id: 3,
          option: "أدوار واضحة لكل موظفي الوزارة",
          isCorrect: false,
        },
        {
          id: 4,
          option: "جميع ما سبق",
          isCorrect: true,
        },
      ],
    },
  ];
  const [correctAns, setCorrectAns] = useState([]);
  const [incorrectAns, setIncorrectAns] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(questions.length - 1);
  const [score, setScore] = useState(0);
  const [selectedChoices, setSelectedChoices] = useState([]); // Declare selectedChoices
  const navigate = useNavigate();
  const { put, get, data } = useFetch();

  const [isActive, setIsActive] = useState(false);
  const [image, setImage] = useState(false);

  const toggleCorrection = () => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
      setSelectedChoices([]);
      setCurrentIndex(currentIndex - 1);
    }, 1000);
  };

  useEffect(() => {
    localStorage.setItem("quiz-3", score);
  }, [score]);

  useEffect(() => {
    if (currentIndex < 0) {
      end();
    }
  }, [currentIndex]);

  const handleChoiceClick = (choice) => {
    const isSelected = selectedChoices.includes(choice.id);

    if (isSelected) {
      setSelectedChoices((prevSelectedChoices) =>
        prevSelectedChoices.filter((item) => item !== choice.id)
      );
    } else {
      const currentQuestion = questions[currentIndex];
      const correctChoices = currentQuestion.choices.filter(
        (choice) => choice.isCorrect
      );
      if (correctChoices.length === selectedChoices.length) {
        setSelectedChoices([choice.id]);
      } else {
        setSelectedChoices((prevSelectedChoices) => [
          ...prevSelectedChoices,
          choice.id,
        ]);
      }
    }
  };

  const [responses, setResponses] = useState([]);

  useEffect(() => {
    if (currentIndex >= 0) {
      const currentQuestion = questions[currentIndex];
      const correctChoices = currentQuestion.choices
        .filter((choice) => choice.isCorrect)
        .map((choice) => choice.id);
      setCorrectAns(correctChoices);
    }
  }, [currentIndex]);

  const answered = () => {
    const correctSelectedChoices = selectedChoices.filter((choiceId) =>
      correctAns.includes(choiceId)
    );
    const incorrectSelectedChoices = selectedChoices.filter(
      (choiceId) => !correctAns.includes(choiceId)
    );
    setIncorrectAns(incorrectSelectedChoices);

    const scoreIncrement = correctSelectedChoices.length;
    const newScore = score + scoreIncrement;

    if (scoreIncrement === correctAns.length) {
      setImage(true);
    } else {
      setImage(false);
    }

    const saudiArabiaTime = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Riyadh",
    });
    const formattedDate = saudiArabiaTime.replace(",", "");

    // Get the user_id from localStorage
    const userId = localStorage.getItem("id");

    // Check if the response for the current question already exists in responses
    const existingResponseIndex = responses.findIndex(
      (response) => response.question === questions[currentIndex].questionTxt
    );

    if (existingResponseIndex !== -1) {
      // If it exists, update the existing response with the new selection
      const updatedResponse = responses[existingResponseIndex];
      updatedResponse.user_answers = selectedChoices.map(
        (choiceId) =>
          questions[currentIndex].choices.find(
            (choice) => choice.id === choiceId
          ).option
      );

      updatedResponse.correct_answers = questions[currentIndex].choices
        .filter((choice) => choice.isCorrect)
        .map((choice) => choice.option);

      // Add user_id to the response
      updatedResponse.user_id = userId;

      const updatedResponses = [...responses];
      updatedResponses[existingResponseIndex] = updatedResponse;

      setResponses(updatedResponses);
    } else {
      // If it doesn't exist, create a new response
      const individualResponse = {
        question: questions[currentIndex].questionTxt,
        user_answers: selectedChoices.map(
          (choiceId) =>
            questions[currentIndex].choices.find(
              (choice) => choice.id === choiceId
            ).option
        ),
        correct_answers: questions[currentIndex].choices
          .filter((choice) => choice.isCorrect)
          .map((choice) => choice.option),
        time_answered: formattedDate,
        user_name: localStorage.getItem("name"),
      };

      setResponses((prevResponses) => [...prevResponses, individualResponse]);
    }

    setScore(newScore);
    toggleCorrection();
  };

  const handleTouchStart = (choice) => (event) => {
    event.preventDefault();
    handleChoiceClick(choice);
  };

  const end = async () => {
    try {
      await put(`/users/${localStorage.getItem("id")}/score-3.json`, score);
    } catch (e) {}

    axios
      .get(
        `https://cx-test-b63e7-default-rtdb.firebaseio.com/api/deps/${Number(
          localStorage.getItem("depId") - 1
        )}/score-3.json`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        axios
          .put(
            `https://cx-test-b63e7-default-rtdb.firebaseio.com/api/deps/${Number(
              localStorage.getItem("depId") - 1
            )}/score-3.json`,
            score + Number(response.data),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            axios
              .post(
                "https://cx-test-b63e7-default-rtdb.firebaseio.com/api/responses-three.json",
                responses,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then(() => navigate("/results/3"))
              .catch((e) => {});
          })
          .catch((e) => {});
      })
      .catch((e) => {});
  };

  const exit = () => {
    localStorage.removeItem("quiz-3");
    navigate("/select-game");
  };

  return (
    <>
      <div
        className={`${styles.feedbackContainer} ${
          isActive ? styles.active : ""
        }`}
      >
        <img src={image ? correct : incorrect} alt="Feedback" />
      </div>
      <Header onEnd={exit} />

      <div className={styles.container}>
        <div className={styles.clock}>
          <Stopwatch />
        </div>
        {questions.map((question, index) => (
          <div
            className={`${styles.card} ${
              currentIndex === index ? styles.activeCard : styles.inactiveCard
            }`}
            key={question.id}
          >
            <div className={styles.questionContainer}>
              <img src={question.img} alt="Question" />
              <p>{question.questionTxt}</p>
            </div>
            <p
              style={{
                height: 0,
                margin: 0,
                marginTop: "0.75vh",
                fontSize: "2.2vh",
                width: "85%",
                textAlign: "right",
                color: "white",
              }}
            >{`${selectedChoices.length}/${
              question.choices.filter((choice) => choice.isCorrect).length
            }`}</p>
            <div className={styles.choicesContainer}>
              {question.choices.map((choice) => (
                <>
                  <ChoiceCard
                    key={choice.id}
                    choice={choice}
                    selected={
                      isActive
                        ? correctAns.includes(choice.id)
                        : selectedChoices.includes(choice.id)
                    }
                    incorrect={isActive && incorrectAns.includes(choice.id)}
                    onClick={handleChoiceClick}
                    onTouchStart={() => handleTouchStart(choice)}
                  />
                </>
              ))}
            </div>
          </div>
        ))}
        <div className={styles.nextBtn} onClick={answered} />
      </div>
    </>
  );
};

export default Quiz;
