import React, { useState, useEffect } from "react";
import styles from "./PopupCard.module.css";

const PopupCard = ({ choices, btnStyle, onSelect, title, txt }) => {
  const [show, setShow] = useState(false);
  const [closing, setClosing] = useState(false);
  const [seleced, setSelected] = useState("");

  useEffect(() => {
    if (closing) {
      const timer = setTimeout(() => {
        setShow(false);
        setClosing(false);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [closing]);

  const handleSelection = (choice) => {
    setSelected(choice.txt);
    setClosing(true);
    onSelect(choice.id);
  };

  return (
    <>
      {show ? (
        <>
          <div
            style={{
              width: "500vw",
              height: "500vh",
              position: "fixed",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              backdropFilter: "blur(5px)",
              zIndex: 19,
              top: "-250%",
              left: "-120%",
            }}
            onClick={() => setClosing(true)}
          />
          <div className={`${styles.card} ${closing ? styles.hideCard : ""}`}>
            <h2>{title}</h2>
            <div className={styles.scroll}>
              {choices.slice(1).map((choice, index) => (
                <div
                  key={choice.id}
                  className={styles.content}
                  onClick={() => {
                    handleSelection(choice);
                  }}
                >
                  <h2>{choice.txt}</h2>
                </div>
              ))}
            </div>
          </div>
          <div style={{ ...btnStyle, zIndex: 1 }} />
        </>
      ) : (
        <div
          style={btnStyle}
          className={styles.button}
          onClick={() => setShow(true)}
        >
          {txt}
        </div>
      )}
    </>
  );
};

export default PopupCard;
