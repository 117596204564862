import React, { useEffect, useState } from "react";
import styles from "./ResultsPage.module.css";
import cup from "../assets/new/cup.svg";
import share from "../assets/new/shareBtn.svg";
import n1 from "../assets/n1.svg";
import n2 from "../assets/n2.svg";
import n3 from "../assets/n3.svg";
import n4 from "../assets/n4.svg";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Components/Header";
import useFetch from "../hooks/useFetch";
import axios from "axios";

const ResultsPage = () => {
  const navigate = useNavigate();
  const storedValue = localStorage.getItem("name");
  const [score, setScore] = useState(0);
  const depId = localStorage.getItem("depId");

  const img = [n1, n2, n3, n4];

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const [ranks, setRanks] = useState([]);
  const [userRank, setUserRank] = useState(0);
  const [time, setTime] = useState("");

  const { gameId } = useParams();

  useEffect(() => {
    const timeFormat = formatTime(localStorage.getItem(`quiz-2`));
    setTime(timeFormat);
  }, []);

  useEffect(() => {
    axios
      .get("https://cx-test-b63e7-default-rtdb.firebaseio.com/api/deps.json")
      .then((response) => {
        if (gameId === "2") {
          const sortedData = Object.values(response.data)
            .filter((item) => item["score-2"] !== 1e39)
            .sort((a, b) => a[`score-2`] - b[`score-2`])
            .map((item) => ({
              ...item,
              "score-2": formatTime(item["score-2"]),
            }));
          setRanks(sortedData);
          const rankUser = sortedData.findIndex((item) => item.id == depId);
          setUserRank(rankUser + 1);
        } else {
          const sortedData = Object.values(response.data)
            .filter((item) => item[`score-${gameId}`] !== 0)
            .sort((a, b) => b[`score-${gameId}`] - a[`score-${gameId}`]);
          setRanks(sortedData);
          const rankUser = sortedData.findIndex((item) => item.id == depId);
          setUserRank(rankUser + 1);
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.ranks}>
        {ranks.slice(0, 4).map((rank, i) => (
          <div className={styles.place} key={rank.id}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "rotate(-2deg)",
              }}
            >
              <p>{rank.name}</p>
            </div>
            <div className={styles.ranksH2Container}>
              <h2 className={styles.ranksH2}>
                {gameId === "2" ? "دقيقة" : "نقطة"}
              </h2>
              <h2 className={styles.ranksH2}>{rank[`score-${gameId}`]}</h2>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.details}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 className={styles.name}>{storedValue}</h3>
          <div className={styles.myResult}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0.2vh",
                  transform: "rotate(-2deg)",
                }}
              >
                <h2>{gameId === "2" ? "دقيقة" : "نقطة"}</h2>
                <h2 style={{ color: "#2bb374" }}>
                  {gameId === "2"
                    ? time
                    : localStorage.getItem(`quiz-${gameId}`)}
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "-9vh",
                  transform: "rotate(-2deg)",
                }}
              >
                <h3>
                  فريقك في المرتبة{" "}
                  <span className={styles.span2}>{`( ${userRank} )`}</span>
                </h3>
              </div>
            </div>
            <img src={cup} />
          </div>
        </div>
        <div
          className={styles.shareContainer}
          onClick={() => navigate(`/share-result/${gameId}`)}
        >
          <p style={{ color: "white" }}>شارك النتيجة مع زملائك</p>
          <img src={share} style={{ height: "7vh" }} />
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
