import { useNavigate } from "react-router-dom";
import Quiz from "../../Components/GameTwoComponents/Quiz";
import Header from "../../Components/Header";

const GameTwo = () => {
  const navigate = useNavigate();
  
  const end = () => {
    localStorage.removeItem("quiz-2");
    navigate("/select-game");
  };

  return (
    <>
      <Header onEnd={end} />
      <Quiz />
    </>
  );
};

export default GameTwo;
