// useFetch.js
import { useState } from 'react';
import axios from 'axios';

const useFetch = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const get = async (endpoint) => {
    try {
      setLoading(true);
      const response = await axios.get(`https://cx-test-b63e7-default-rtdb.firebaseio.com/api/${endpoint}`);
      setData(response.data);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const post = async (endpoint, requestData) => {
    try {
      setLoading(true);
      const response = await axios.post(`https://cx-test-b63e7-default-rtdb.firebaseio.com/api/${endpoint}`, requestData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
      setData(response.data);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const put = async (endpoint, requestData) => {
    try {
      setLoading(true);
      const response = await axios.put(`https://cx-test-b63e7-default-rtdb.firebaseio.com/api/${endpoint}`, requestData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
      setData(response.data);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, get, post, put };
};

export default useFetch;
