import React, { useEffect, useState } from "react";
import styles from "./QFive.module.css";
import PopupCard from "./PopupCard";

import correct from "../../assets/correct.svg";
import incorrect from "../../assets/incorrect.svg";

const QFive = ({ onNext, load }) => {
  const choices = [
    { id: 0, txt: "" },
    { id: 1, txt: "إجراء بحث عن المستفيدين" },
    { id: 2, txt: "تطوير خطة التنفيذ وإجراء تقييم التغيير" },
    { id: 3, txt: "تحديد تحسين تجربة المستفيد المطلوبة بشكل استباقي" },
    { id: 4, txt: "جمع بيانات المستفيدين ومرئيات الموظفين" },
    { id: 5, txt: "إجراء تقييم النضج وتأثير تجربة المستفيد" },
    { id: 6, txt: "عقد ورش عمل ذات تصميم مشترك لحل مشاكل تجربة المستفيد" },
  ];

  const btnStyle = {
    whiteSpace: "pre-wrap",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "12%",
    height: "100%",
    fontSize: "1.3vw",
    textAlign: "center",
    paddingLeft: "1vw",
    paddingRight: "1vw",
    fontFamily: "HRSD",
    fontWeight: "bold",
    color: "#fff",
    // backgroundColor: "rgba(3, 131, 129, 0.5)",
    whiteSpace: "pre-wrap",
  };

  const correctAns = [3, 5, 4, 1, 6, 2];
  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(choices.length - 1).fill(0)
  );
  const [image, setImage] = useState(false);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const allNonZero = selectedAnswers.every((value) => value !== 0);
    if (allNonZero) {
      const isEqual =
        JSON.stringify(correctAns) === JSON.stringify(selectedAnswers);
      if (isEqual) {
        setImage(true);
        toggleCorrection();
        setTimeout(() => {
          onNext();
        }, 300);
      } else {
        setImage(false);
        toggleCorrection();
        setSelectedAnswers((prevSelectedAnswers) =>
          prevSelectedAnswers.map((value, index) =>
            correctAns[index] === value ? value : 0
          )
        );
      }
    }
  }, [selectedAnswers]);
  const toggleCorrection = () => {
    setIsActive(true);

    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };

  const handleSelectChange = (id, index) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[index] = id;
    setSelectedAnswers(newSelectedAnswers);
  };

  return (
    <>
      <div
        className={`${styles.feedbackContainer} ${
          isActive ? styles.active : ""
        }`}
      >
        <img src={image ? correct : incorrect} alt="Feedback" />
      </div>
      <div className={styles.container}>
        <h2 className={styles.qTxt}>
          نموذج لسلسلة القيمة لمركز
          <br />
          <span className={styles.span}>تميز تجربة المستفيد</span>
        </h2>
        {load ? (
          <div className={styles.ansContainer}>
            <div className={styles.selectContainer}>
              {choices.slice(1).map((choice, index) => (
                <PopupCard
                  title={"اربــط الإجراءات مع عناصر السلسلة"}
                  choices={choices}
                  onSelect={(id) => {
                    handleSelectChange(id, index);
                  }}
                  key={choice.id}
                  btnStyle={btnStyle}
                  txt={choices[selectedAnswers[index]].txt}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.prvContainer} />
        )}
        <h2
          className={styles.qTxt}
          style={{ fontSize: "1.5vw", marginLeft: "1vw" }}
        >
          اربــط الإجراءات مع عناصر السلسلة
        </h2>
      </div>
    </>
  );
};

export default QFive;
