import React, { useEffect, useState } from "react";
import styles from "./SharePage.module.css";
import download from "../assets/new/shareDownload.svg";
import qr from "../assets/new/qr.svg";
import Header from "../Components/Header";
import QRCode from "qrcode.react";
import { useParams } from "react-router-dom";

const API_KEY = "6b931af13fb8b9933d10a4244a03689c";

const SharePage = () => {
  const { gameId } = useParams();
  const [websiteURL, setWebsiteURL] = useState(
    `https://cx-test-b63e7.web.app/results/${gameId}/${localStorage.getItem("id")}`
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [snapshotLink, setSnapshotLink] = useState("");
  const [qrCodeSize, setQRCodeSize] = useState(250);

  const calculateQRCodeSize = () => {
    const desiredSizeInVH = 25; // Change this to your desired size in vh
    const viewportHeight = window.innerHeight;
    return (viewportHeight * desiredSizeInVH) / 100;
  };

  useEffect(() => {
    const handleResize = () => {
      const newSize = calculateQRCodeSize();
      setQRCodeSize(newSize);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const validateLink = () => {
    if (websiteURL.startsWith("https://")) {
      setErrorMessage("");
      showLink();
    } else {
      setErrorMessage("Enter a correct website link");
    }
  };

  useEffect(() => {
    setSnapshotLink(getSnapshotURL())
  })

  const getSnapshotURL = () => {
    const baseURL = "https://api.screenshotlayer.com/api/capture";
    const params = `?access_key=${API_KEY}&url=${encodeURIComponent(
      websiteURL
    )}&fullpage=1&viewport=1280x832&width=1280`;
    return baseURL + params;
  };

  const downloadSnapshot = () => {
    const snapshotURL = getSnapshotURL();
    const link = document.createElement("a");
    link.href = snapshotURL;
    link.download = "snapshot.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const showLink = () => {
    setSnapshotLink(getSnapshotURL());
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.card}>
        <div className={styles.qrContainer}>
          {/* <img src={qr} /> */}
          <QRCode
            value={snapshotLink}
            size={qrCodeSize}
            bgColor="transparent"
            fgColor="white"
            level="L"
          />
          <h2>إمسح الرمز لنشر النتيجة من الهاتف</h2>
        </div>
        <div className={styles.txt} onClick={downloadSnapshot}>
          <h2>اضغط هنا لتحميــــــــــل الصـــــــــــورة</h2>
          <img src={download} />
        </div>
      </div>
    </div>
  );
};

export default SharePage;
