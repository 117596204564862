import React, { useState } from "react";
import styles from "./GameCard.module.css";

const GameCard = ({ title, image, linkTo, height, width, isActive }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    // Handle the click action, e.g., navigate to the link
    window.location.href = linkTo;
  };

  return (
    <div
      className={`${styles.card} ${isHovered ? styles.hovered : ""} ${
        !isActive ? styles.inactive :  ""
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <img src={image} className={styles.img} />
    </div>
  );
};

export default GameCard;
