import React, { useState, useEffect } from "react";
import CardFormComponent from "../Components/CardFormComponent";
import styles from "./FormPage.module.css";

import Header from "../Components/Header";
import { useNavigate } from "react-router-dom";

const FormPage = () => {
  const navigate = useNavigate();
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("id")) {
      setShouldRender(true);
    } else {
      navigate("/select-game");
    }
  }, [navigate]);

  if (shouldRender)
    return (
      <div className={styles.container}>
        <Header />
        <CardFormComponent />
      </div>
    );
  else return <></>;
};

export default FormPage;
