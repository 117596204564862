import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import styles from "./App.module.css";
import SelectGame from "./Pages/SelectGame";
import FormPage from "./Pages/FormPage";
import GameOne from "./Pages/Games/GameOne";
import GameThree from "./Pages/Games/GameThree";
import GameSplash from "./Pages/Games/GameSplash";
import ResultsPage from "./Pages/ResultsPage";
import SnapshotPage from "./Pages/SnapshotPage";
import SharePage from "./Pages/SharePage";
import { useState, useEffect } from "react";
import GameTwo from "./Pages/Games/GameTwo";
import Splash from "./Pages/Splash";
import rotate from "./assets/new/rotate.svg";
import ipad from "./assets/new/ipad.svg";

function App() {
  const [isLandscape, setIsLandscpae] = useState(
    window.innerWidth > window.innerHeight
  );
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroidUserAgent = /android/i.test(userAgent);

    const screenWidth =
      window.innerWidth || document.documentElement.clientWidth;
    const isSmallScreen = screenWidth < 1000;

    setIsAndroid(isAndroidUserAgent && isSmallScreen);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscpae(window.innerWidth > window.innerHeight);
    };

    // Add a resize event listener
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!isLandscape && (
        <div className={styles.rotate}>
          <img src={rotate} />
          <h2>الرجاء استعمال الجوال بالعرض</h2>
        </div>
      )}
      {isAndroid && (
        <div className={styles.rotate}>
          <img src={ipad} />
          <h2>الرجاء استعمال شاشة أكبر</h2>
        </div>
      )}
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/signup" element={<FormPage />} />
        {/* {registered && ( */}
          <>
            <Route path="*" element={<Navigate to="/select-game" />} />
            <Route path="/select-game" element={<SelectGame />} />
            <Route path="/splash/:id" element={<GameSplash />} />
            <Route path="/game/1" element={<GameOne />} />
            <Route path="/game/2" element={<GameTwo />} />
            <Route path="/game/3" element={<GameThree />} />
            <Route path="/results/:gameId" element={<ResultsPage />} />
            <Route path="/share-result/:gameId" element={<SharePage />} />
          </>
        {/* )} */}
          <Route path="/results/:gameId/:userId" element={<SnapshotPage />} />
      </Routes>
    </>
  );
}

export default App;
