import React, { useEffect, useState } from "react";
import styles from "./Quiz.module.css";
import QOne from "./QOne";
import QTwo from "./QTwo";
import QThree from "./QThree";
import QFour from "./QFour";
import QFive from "./QFive";
import { useNavigate } from "react-router-dom";
import Stopwatch from "../GameThreeComponents/Stopwatch";
import axios from "axios";

function Quiz() {
  const [end, setEnd] = useState(false);
  const [time, setTime] = useState(0);
  const [responses, setResponses] = useState([]);
  const [pause, setPause] = useState(true);
  const [i, setI] = useState();

  useEffect(() => {
    let timeoutId;
  
    if (pause) {
      timeoutId = setTimeout(() => {
        setPause(false);
      }, 30000);
    }
  
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [pause, setPause]);

  useEffect(() => {
    setI(Math.floor(Math.random() * 4));
  }, []);

  const handleNextClick = () => {
    setEnd(true);
  };

  useEffect(() => {
    localStorage.setItem("quiz-2", time);
  }, [time]);

  const addResponse = (response) => {
    if (response !== 0) {
      const saudiArabiaTime = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Riyadh",
      });
      const formattedDate = saudiArabiaTime.replace(",", "");
      const rObject = {
        user_name: localStorage.getItem("name"),
        response,
        timeFinished: formattedDate,
      };
      setResponses([...responses, rObject]);
    }
  };

  useEffect(() => {
    if (time > 0) {
      axios
        .put(
          `https://cx-test-b63e7-default-rtdb.firebaseio.com/api/users/${localStorage.getItem(
            "id"
          )}/score-2.json`,
          time,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          axios
            .get(
              `https://cx-test-b63e7-default-rtdb.firebaseio.com/api/deps/${Number(
                localStorage.getItem("depId") - 1
              )}/score-2.json`,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              if (response.data > time) {
                axios
                  .put(
                    `https://cx-test-b63e7-default-rtdb.firebaseio.com/api/deps/${Number(
                      localStorage.getItem("depId") - 1
                    )}/score-2.json`,
                    time,
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  )
                  .catch((e) => {});
              }
            })
            .catch((e) => {});
        })
        .then(() => {
          axios
            .post(
              "https://cx-test-b63e7-default-rtdb.firebaseio.com/api/responses-two.json",
              responses,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(() => {
              navigate("/results/2");
            })
            .catch((e) => {});
        })
        .catch((e) => {});
    }
  }, [time]);

  const endGame = (t) => {
    setTime(t);
  };

  const navigate = useNavigate();
  const questions = [
    <QOne onNext={handleNextClick} load={!pause} />,
    <QTwo onNext={handleNextClick} load={!pause} />,
    <QThree onNext={handleNextClick} load={!pause} />,
    // <QFour onNext={handleNextClick} load={!pause} />,
    <QFive onNext={handleNextClick} load={!pause} />,
  ];

  return (
    <div className={styles.container}>
      <div style={{ position: "absolute", top: "0.5vh", left: "11vw" }}>
        <Stopwatch
          onPause={(response) => addResponse(response)}
          onEnd={(t) => endGame(t)}
          end={end}
          pause={pause}
        />
      </div>
      {pause && (
        <div
          className={styles.nextBtn}
          onClick={() => {
            setPause(false); // Start the game
          }}
        />
      )}
      {questions[i]}
    </div>
  );
}

export default Quiz;
