import React, { useEffect, useState } from "react";
import styles from "./QOne.module.css";
import PopupCard from "./PopupCard";

import correct from "../../assets/correct.svg";
import incorrect from "../../assets/incorrect.svg";

const QOne = ({ onNext, load }) => {
  const choices = [
    { id: 0, txt: "" },
    { id: 1, txt: "تحديد الأولويات" },
    { id: 2, txt: "التصميم" },
    { id: 3, txt: "ترسيخ تجربة المستفيد" },
    { id: 4, txt: "الضبط" },
    { id: 5, txt: "القياس" },
    { id: 6, txt: "التمكين" },
    { id: 7, txt: "المراقبة" },
    { id: 8, txt: "البحث" },
    { id: 9, txt: "الجودة" },
    { id: 10, txt: "المعايير الدولية" },
  ];

  const btnStyle = {
    width: "12%",
    aspectRatio: 2 / 1.3,
    marginBottom: "6%",
    marginLeft: "5%",
    fontSize: "0.95vw",
    textAlign: "center",
    fontFamily: "HRSD",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "rgba(3, 131, 129, 0.2)",
    whiteSpace: "pre-wrap",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const correctAns = [8, 1, 2, 6, 5, 3];
  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(choices.length - 5).fill(0)
  );
  const [image, setImage] = useState(false);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const allNonZero = selectedAnswers.every((value) => value !== 0);
    if (allNonZero) {
      const isEqual =
        JSON.stringify(correctAns) === JSON.stringify(selectedAnswers);
      if (isEqual) {
        setImage(true);
        toggleCorrection();
        setTimeout(() => {
          onNext();
        }, 300);
      } else {
        setImage(false);
        toggleCorrection();
        setSelectedAnswers((prevSelectedAnswers) =>
          prevSelectedAnswers.map((value, index) =>
            correctAns[index] === value ? value : 0
          )
        );
      }
    }
  }, [selectedAnswers]);
  const toggleCorrection = () => {
    setIsActive(true);

    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };

  const handleSelectChange = (id, index) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[index] = id;
    setSelectedAnswers(newSelectedAnswers);
  };

  return (
    <>
      <div
        className={`${styles.feedbackContainer} ${
          isActive ? styles.active : ""
        }`}
      >
        <img src={image ? correct : incorrect} alt="Feedback" />
      </div>
      <div className={styles.container}>
        <h2 className={styles.qTxt}>
          لكي تستطيع الأجهزة العامة من تقديــم تجارب مرضية وخدمات عالية الجودة
          <br />
          يجب عليها تطبيــــــق أفضل الممارسات في{" "}
          <span className={styles.span}>
            مجـــــال إدارة تجربــــــة المستفيـــــــــد
          </span>
        </h2>
        {load ? (
          <div className={styles.ansContainer}>
            <div className={styles.selectContainer}>
              {choices.slice(5).map((choice, index) => (
                <PopupCard
                  title={"تذكر أفضل الممارسات في سلسلة المستفيد"}
                  txt={choices[selectedAnswers[index]].txt}
                  key={choice.id}
                  choices={choices}
                  btnStyle={btnStyle}
                  onSelect={(id) => {
                    handleSelectChange(id, index);
                  }}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.prvContainer} />
        )}
      </div>
    </>
  );
};

export default QOne;
