import React from "react";
import styles from "./Header.module.css";

import logos from "../assets/new/hrlogo.svg";
import { useNavigate, useParams } from "react-router-dom";

const Header = ({ onEnd }) => {
  const navigate = useNavigate();

  const { gameId, userId } = useParams();

  const exitWebsite = () => {
    if (window.location.pathname === "/select-game") {
      localStorage.clear();
      navigate("/signup");
    } else if (
      window.location.pathname === "/game/1" ||
      window.location.pathname === "/game/2" ||
      window.location.pathname === "/game/3"
    ) {
      onEnd();
    } else if (window.location.pathname === "/signup") {
      navigate("/");
    } else {
      navigate("/select-game");
    }
  };

  return (
    <div className={styles.header}>
      <div className={`${styles.exitBtn} ${gameId && userId ? styles.transparent : ""}`} onClick={exitWebsite}/>
      <img className={styles.logo} src={logos} />
    </div>
  );
};

export default Header;
