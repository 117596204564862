import React, { useEffect, useState } from "react";
import styles from "./GameSplash.module.css";
import colors from "../../Colors.module.css";
import GameCard from "../../Components/GameCard";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header";
import G1Splash from "../../assets/new/October/G1Splash.png";
import G2Splash from "../../assets/new/October/G2Splash.png";
import G3Splash from "../../assets/new/October/G3Splash.png";

const GameSplash = () => {
  const { id } = useParams();
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const sImages = [G1Splash, G2Splash, G3Splash]

  useEffect(() => {
    if (localStorage.getItem(`quiz-${id}`)) {
      navigate(`/results/${id}`);
    } else {
      setLoad(true);
    }
  }, []);

  return (
    <>
      {load && (
        <>
          <Header />
          <div className={styles.container}>
            <div className={styles.gameCard}>
              <GameCard
                isActive={true}
                title={"اللعبة " + id}
                image={sImages[id-1]}
                linkTo={"/game/" + id}
                height={400}
                width={1000}
              />
            </div>
            {id === "1" && <h2> جاوب على الأسئلة قبل انتهاء الوقت</h2>}
            {id === "2" && <h2> اختبر ذاكرتك واحفظ المخططات الصورية</h2>}
            {id === "3" && <h2> اختر الأصح</h2>}
          </div>
        </>
      )}
    </>
  );
};

export default GameSplash;
