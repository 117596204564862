import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import timer from "../../assets/new/timer.svg";
import styles from "./Quiz.module.css";

const Timer = ({ onEnd }) => {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  const updateTimer = () => {
    if (seconds > 0) {
      setSeconds(seconds - 1);
    } else if (minutes > 0) {
      setMinutes(minutes - 1);
      setSeconds(59);
    }
  };

  useEffect(() => {
    if (seconds === 0 && minutes === 0) {
      onEnd();
    }
  }, [seconds]);

  useEffect(() => {
    const timer = setInterval(updateTimer, 1000);

    // Cleanup the timer when the component unmounts
    return () => clearInterval(timer);
  }, [minutes, seconds]);

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return (
    <div className={styles.timerContainer}>
      <img src={timer} alt="Timer" />
      <h4>{`${formattedMinutes}:${formattedSeconds}`}</h4>
    </div>
  );
};
export default Timer;
