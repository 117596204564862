import React, { useState, useEffect, useMemo } from "react";
import TinderCard from "react-tinder-card";
import styles from "./Quiz.module.css";
import { useNavigate } from "react-router-dom";

import correct from "../../assets/correct.svg";
import incorrect from "../../assets/incorrect.svg";
import useFetch from "../../hooks/useFetch";
import axios from "axios";
import Timer from "./Timer";

const Quiz = ({ questionsData }) => {
  const { put } = useFetch();

  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(1000);

  const [image, setImage] = useState(true);
  const [score, setScore] = useState(0);
  const [responses, setResponses] = useState([]);
  const [body, setBody] = useState([]);

  function getRandomElements(arr, numElements) {
    const shuffled = arr.slice(); // Clone the original array
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements randomly
    }
    return shuffled.slice(0, numElements);
  }

  // useEffect(() => {
  //   const timeoutId = setTimeout(async () => {
  //     end();
  //   }, 30000);

  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, []);

  useEffect(() => {
    setQuestions(getRandomElements(questionsData, 10));
    setCurrentIndex(9);
  }, [questionsData]);

  const removeDuplicateResponses = () => {
    const uniqueResponses = [];
    const seenQuestions = new Set();

    for (const response of responses) {
      const question = response.question;

      if (!seenQuestions.has(question)) {
        seenQuestions.add(question);
        uniqueResponses.push(response);
      }
    }
    return uniqueResponses;
  };

  useEffect(() => {
    setBody(removeDuplicateResponses());
  }, [responses]);
  useEffect(() => {
    console.log(body);
  }, [body]);

  const childRefs = useMemo(
    () =>
      Array(questions.length)
        .fill(0)
        .map((i) => React.createRef()),
    [questions]
  );

  // Swiping
  const canSwipe = currentIndex >= 0;

  useEffect(() => {
    localStorage.setItem("quiz-1", score);
  }, [score, questions]);

  // set last direction and decrease current index
  const handleCardChange = (direction, nameToDelete, index) => {
    answered(direction === "left" ? false : true);

    // handle the case in which go back is pressed before card goes outOfFrame
    // if (currentIndex >= index) {
    //   childRefs[index].current.restoreCard();
    // }
  };

  const swipe = async (dir) => {
    if (canSwipe && currentIndex >= 0) {
      answered(dir === "left" ? false : true);
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  const answered = (answer, id) => {
    const saudiArabiaTime = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Riyadh",
    });

    const formattedDate = saudiArabiaTime.replace(",", "");

    const question = questions[currentIndex].text;

    const newResponse = {
      user_name: localStorage.getItem("name"),
      question,
      user_answer: answer,
      correct_answer: questions[currentIndex].answer,
      time_answered: formattedDate,
    };
    setResponses((prevResponses) => [...prevResponses, newResponse]);

    if (answer === questions[currentIndex].answer) {
      setImage(true);
      setTimeout(() => {
        toggleCorrection();
      }, 300);
      setScore(score + 1);
    } else {
      setImage(false);
      setTimeout(() => {
        toggleCorrection();
      }, 300);
    }
    setTimeout(() => {
      setCurrentIndex(currentIndex - 1);
    }, 300);
  };
  const end = async () => {
    try {
      await put(
        `/users/${localStorage.getItem("id")}/score-1.json`,
        localStorage.getItem("quiz-1")
      );
    } catch (e) {}

    axios
      .post(
        "https://cx-test-b63e7-default-rtdb.firebaseio.com/api/responses-one.json",
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((r) => {
        console.log(body);
      })
      .catch((e) => {});
    axios
      .get(
        `https://cx-test-b63e7-default-rtdb.firebaseio.com/api/deps/${Number(
          localStorage.getItem("depId") - 1
        )}/score-1.json`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        axios
          .put(
            `https://cx-test-b63e7-default-rtdb.firebaseio.com/api/deps/${Number(
              localStorage.getItem("depId") - 1
            )}/score-1.json`,
            score + Number(response.data),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .catch((e) => {});
      })
      .catch((e) => {})
      .finally(() => {
        navigate("/results/1");
      });
  };

  const [isActive, setIsActive] = useState(false);

  const toggleCorrection = () => {
    setIsActive(true);

    // Reset the animation after a short delay (e.g., 300ms)
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };

  useEffect(() => {
    if (currentIndex === -1 && questions.length !== 0) {
      end();
    }
  }, [currentIndex, questions]);

  return (
    <>
      <div
        className={`${styles.feedbackContainer} ${
          isActive ? styles.active : ""
        }`}
      >
        <img src={image ? correct : incorrect} alt="Feedback" />
      </div>

      <Timer onEnd={end} />
      <div className={styles.quizContainer}>
        <div className={styles.disagreeBtn} onClick={() => swipe("left")} />
        <div className={styles.cardPocket}>
          {questions.map((item, index) => (
            <>
              {index === currentIndex && (
                <TinderCard
                  ref={childRefs[index]}
                  className={`${styles.card} ${
                    index === currentIndex
                      ? styles.activeCard
                      : styles.inactiveCard
                  }`}
                  key={item.id}
                  onSwipe={(dir) => handleCardChange(dir, item.name, index)}
                >
                  <div className={styles.cardContent}>
                    <img
                      className={styles.image}
                      src={item.img}
                      alt={item.text}
                    />
                    <h3 className={styles.notebookText}>{item.text}</h3>
                  </div>
                </TinderCard>
              )}
            </>
          ))}
        </div>
        <div className={styles.agreeBtn} onClick={() => swipe("right")} />
      </div>
    </>
  );
};

export default Quiz;
