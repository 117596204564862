import React, { useEffect, useState } from "react";
import styles from "./CardFormComponent.module.css";
import { useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";

const deps = [
  { id: 0, name: "" },
  { id: 1, name: "وكالة الشؤون الدولية" },
  { id: 2, name: "‏وكالة الشؤون الاستراتيجية وتحقيق الرؤية" },
  { id: 3, name: "‏وكالة الشؤون التنفيذية" },
  { id: 4, name: "‏وكالة رأس المال البشري" },
  { id: 5, name: "‏وكالة الابتكار والتميز" },
  { id: 6, name: "‏ وكالة التخطيط والتطوير" },
  { id: 7, name: "‏وكالة الخدمات المساندة" },
  { id: 8, name: "‏وكالة التحول الرقمي" },
  { id: 9, name: "وكالة تجربة المستفيد والفروع" },
  { id: 10, name: "‏وكالة فروع المناطق" },
  { id: 11, name: "‏وكالة شؤون العمل" },
  { id: 12, name: "‏وكالة شؤون العمالية" },
  { id: 13, name: "‏وكالة سياسات العمل" },
  { id: 14, name: "‏وكالة التفتيش وتطوير بيئة العمل" },
  { id: 15, name: "‏وكالة التوطين" },
  { id: 16, name: "‏وكالة تمكين المرأة" },
  { id: 17, name: "‏وكالة تنمية المجتمع" },
  { id: 18, name: "‏وكالة التمكين والضمان الاجتماعي" },
  { id: 19, name: "‏وكالة التأهيل والتوجيه الاجتماعي" },
  { id: 20, name: "وكالة الرقابة وتطوير بيئة العمل" },
];
// const dummyCities = ["","مدينة ١", "مدينة ٢", "مدينة ٣", "مدينة ٤", "مدينة ٥"];

const CardFormComponent = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 960); // Adjust this threshold as needed
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const navigate = useNavigate();
  const [part, setPart] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    gender: "ذكر",
    department: deps[0].id,
    // city: dummyCities[0],
  });
  const [formErrors, setFormErrors] = useState({});
  const { post, data } = useFetch();

  useEffect(() => {
    if (data) {
      localStorage.setItem("id", data.name);
      localStorage.setItem("depId", formData.department);
      localStorage.setItem(
        "name",
        `${formData.firstName} ${formData.lastName}`
      );
      setTimeout(() => {
        navigate("/select-game");
      }, 500);
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Special handling for the "department" field
    if (name === "department") {
      const departmentId = parseInt(value); // Convert the value to an integer
      setFormData({
        ...formData,
        department: departmentId, // Store the department ID
      });
      // } else if (name === "city") {
      //   setFormData({
      //     ...formData,
      //     city: value, // Store the city name
      //   });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    // Clear the specific field error when it's being updated
    setFormErrors({
      ...formErrors,
      [name]: "",
    });

    // Validate phone number input to allow only numeric characters
    if (name === "phoneNumber" && !/^\d+$/.test(value)) {
      setFormErrors({
        ...formErrors,
        [name]: "رقم الهاتف يجب أن يحتوي على أرقام فقط",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields and set form errors accordingly
    const errors = {};
    for (const key in formData) {
      if (!formData[key]) {
        errors[key] = "هذا الحقل مطلوب";
      }
    }

    // Check if there are any errors
    if (Object.keys(errors).length === 0) {
      try {
        const body = {
          first_name: formData.firstName,
          last_name: formData.lastName,
          department_id: formData.department,
          phone: formData.phoneNumber,
          gender: formData.gender === "ذكر" ? "male" : "female",
          // city: formData.city,
        };
        await post("users.json", body);
      } catch (err) {
        console.error("POST request error:", err);
      }
      localStorage.setItem("id", 1);
      localStorage.setItem(
        "name",
        `${formData.firstName} ${formData.lastName}`
      );
      setTimeout(() => {
        // navigate("/select-game");
      }, 500);
    } else {
      alert("الرجاء تعبئة جميع المعلومات");
    }
  };

  if (isMobile) {
    return (
      <div className={styles.card}>
        <div className={styles.cardTitle}>
          <h2>الرجاء إدخال المعلومات التالية</h2>
        </div>
        <form onSubmit={handleSubmit}>
          {part === 1 ? (
            <>
              <div className={styles.formGroup}>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
                <label htmlFor="firstName">الاسم الأول</label>
              </div>
              <div className={styles.formGroup}>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
                <label htmlFor="lastName">الاسم الأخير</label>
              </div>
              <div className={styles.formGroup}>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                <label htmlFor="phoneNumber">رقم الهاتف</label>
              </div>
            </>
          ) : (
            <>
              <div className={styles.formGroup}>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  className={styles.formSelect}
                  style={{
                    height: "5.2vh",
                  }}
                >
                  <option value="ذكر">ذكر</option>
                  <option value="أنثى">أنثى</option>
                </select>
                <label htmlFor="gender">الجنس</label>
              </div>
              <div className={styles.formGroup}>
                <select
                  id="department"
                  name="department"
                  value={formData.department}
                  onChange={handleInputChange}
                  className={styles.formSelect}
                  style={{
                    height: "5.2vh",
                  }}
                >
                  {deps.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="department">القسم</label>
              </div>
              {/* <div className={styles.formGroup}>
                <select
                  id="cities"
                  name="city"
                  value={formData.department.id}
                  onChange={handleInputChange}
                  className={styles.formSelect}
                  style={{
                    height: "5.2vh",
                  }}
                >
                  {dummyCities.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
                <label htmlFor="department">المدينة</label>
              </div> */}
            </>
          )}
          <div
            className={styles.buttonGroup}
            onClick={(e) => {
              if (part === 1) {
                if (
                  formData.firstName !== "" &&
                  formData.lastName !== "" &&
                  formData.phoneNumber !== ""
                ) {
                  setPart(2);
                } else {
                  alert("الرجاء تعبئة جميع المعلومات");
                }
              } else {
                handleSubmit(e);
              }
            }}
          >
            <p>{part === 1 ? "التالي" : "ابدأ"}</p>
          </div>
        </form>
      </div>
    );
  } else {
    return (
      <div className={styles.cardBig}>
        <div className={styles.cardTitleBig}>
          <h2>الرجاء إدخال المعلومات التالية</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <label htmlFor="firstName">الاسم الأول</label>
          </div>
          <div className={styles.formGroup}>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
            <label htmlFor="lastName">الاسم الأخير</label>
          </div>
          <div className={styles.formGroup}>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            <label htmlFor="phoneNumber">رقم الهاتف</label>
          </div>
          <div className={styles.formGroup}>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              className={styles.formSelect}
              style={{
                height: "5.2vh",
              }}
            >
              <option style={{ color: "black" }} value="ذكر">ذكر</option>
              <option style={{ color: "black" }} value="أنثى">أنثى</option>
            </select>
            <label htmlFor="gender">الجنس</label>
          </div>
          <div className={styles.formGroup}>
            <select
              id="department"
              name="department"
              value={formData.department}
              onChange={handleInputChange}
              className={styles.formSelect}
              style={{
                height: "5.2vh",
              }}
            >
              {deps.map((department) => (
                <option key={department.id} value={department.id} style={{ color: "black" }}>
                  {department.name}
                </option>
              ))}
            </select>
            <label htmlFor="department">القسم</label>
          </div>
          {/* <div className={styles.formGroup}>
            <select
              id="cities"
              name="city"
              value={formData.department.id}
              onChange={handleInputChange}
              className={styles.formSelect}
              style={{
                height: "5.2vh",
              }}
            >
              {dummyCities.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
            <label htmlFor="department">المدينة</label>
          </div> */}
          <div
            className={styles.buttonGroupBig}
            onClick={(e) => handleSubmit(e)}
          >
            <p>ابدأ</p>
          </div>
        </form>
      </div>
    );
  }
};

export default CardFormComponent;
