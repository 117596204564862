import React, { useState, useEffect } from "react";
import styles from "./Stopwatch.module.css";
import stopwatch from "../../assets/new/timer.svg";

const Stopwatch = ({ pause, end, onEnd, onPause }) => {
  const [seconds, setSeconds] = useState(0);
  const [qSeconds, setQSeconds] = useState(0);

  useEffect(() => {
    if (end) {
      onEnd(seconds)
      onPause(qSeconds);
    }
  }, [end]);

  useEffect(() => {
    let interval;

    if (!pause) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
        setQSeconds((prevQSeconds) => prevQSeconds + 1);
      }, 1000);
    } else {
      clearInterval(interval);
      onPause(qSeconds);
      setQSeconds(0);
    }

    return () => clearInterval(interval); // Cleanup the interval

  }, [pause]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.stopwatchContainer}>
        <img src={stopwatch} alt="Stopwatch" />
        <h4>{formatTime(seconds)}</h4>
      </div>
    </div>
  );
};

export default Stopwatch;
