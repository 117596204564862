import React, { useState, useEffect } from "react";
import Quiz from "../../Components/GameThreeComponents/Quiz";
import useFetch from "../../hooks/useFetch";
import styles from "./GameOne.module.css";

const GameThree = () => {
  // const { get, data } = useFetch();
  // const [load, setLoad] = useState(false);

  // useEffect(() => {
  //   get("quiz-three");
  // }, []);

  // useEffect(() => {
  //   if (data) {
  //     setLoad(true);
  //   }
  // }, [data]);

  return (
    <div className={styles.container}>
      {/* {load && (
        <> */}
          <Quiz />
        {/* </>
      )} */}
    </div>
  );
};

export default GameThree;
