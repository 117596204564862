// import React from 'react';
import React, { useEffect, useState } from "react";
import Quiz from "../../Components/GameOneComponents/Quiz";
import Header from "../../Components/Header";
import useFetch from "../../hooks/useFetch";
import Timer from "../../Components/GameOneComponents/Timer";
import styles from "./GameOne.module.css";
import avatar from '../../assets/avatar.png';
import { useNavigate } from "react-router-dom";
import search from '../../assets/new/G1/search.png';
import design from '../../assets/new/G1/design.png';
import tamkeen from '../../assets/new/G1/tamkeen.png';
import operations from '../../assets/new/G1/operations.png';
import misunderstanding from '../../assets/new/G1/misunderstanding.png';
import culture from '../../assets/new/G1/culture.png';
import priorities from '../../assets/new/G1/priorities.png';
import measurements from '../../assets/new/G1/measurements.png';

const GameOne = () => {
  const { data, get, put } = useFetch();
  const [questions, setQuestions] = useState([
    {
      id: 1,
      text: `تصميم تجربة المستفيد هو جهد لمرة واحدة ولا يتطلب تحسين مستمراً`,
      answer: false,
      img: operations,
      userAnswer: null,
    },
    {
      id: 2,
      text: `لا يتضمن تصميم تجربة المستفيد تطوير التكنولوجيا أو المنصات الرقمية`,
      answer: false,
      img: operations,
      userAnswer: null,
    },
    {
      id: 3,
      text: `يتضمن نهج تجربة المستفيد فهم ومعالجة نقاط الاحتياج والألم لدى المستفيد.`,
      answer: true,
      img: operations,
      userAnswer: null,
    },
    {
      id: 4,
      text: `لا يمكن ان يتفوق القطاع الحكومي على القطاع الخاص في جودة الخدمات وفي درجة رضا المستفيدين`,
      answer: false,
      img: misunderstanding,
      userAnswer: null,
    },
    {
      id: 5,
      text: `الأجهزة الحكومية لا تحتاج ولا تستفيد من منهجية التمركز حول المستفيد لأنه مخصص فقط للشركات التجارية و الربحية`,
      answer: false,
      img: misunderstanding,
      userAnswer: null,
    },
    {
      id: 6,
      text: `مبادئ تجربة المستفيد ذات صلة فقط بالأقسام والوكالات التي تتعامل مع المستفيد`,
      answer: false,
      img: misunderstanding,
      userAnswer: null,
    },
    {
      id: 7,
      text: `استراتيجية التمركز حول المستفيد هي ذات صلة وأهمية فقط لموظفي الصف الأمامي من الأقسام التي تتعامل مع المستفيدين عبر نقاط الاتصال المباشر ( خدمة المستفيدين)`,
      answer: false,
      img: misunderstanding,
      userAnswer: null,
    },
    {
      id: 8,
      text: `"تقيم الخدمة" هو المرحلة الأخيرة من سلسلة قيمة تجربة المستفيد`,
      answer: false,
      img: misunderstanding,
      userAnswer: null,
    },
    {
      id: 9,
      text: `لا تنطبق مبادئ تجربة المستفيد على التفاعلات الرقمية، بل على التجارب الشخصية المباشرة فقط`,
      answer: false,
      img: misunderstanding,
      userAnswer: null,
    },
    {
      id: 10,
      text: `يقتصر تنفيذ منهجية ومبادئ تجربة المستفيد على التفاعلات عبر الإنترنت والمنصات الرقمية وتطبيقات الخدمة فقط`,
      answer: false,
      img: misunderstanding,
      userAnswer: null,
    },
    {
      id: 11,
      text: `إنتاج النسخ التجريبية من الخدمات قبل اطلاقها هي عملية مكلفة`,
      answer: false,
      img: priorities,
      userAnswer: null,
    },
    {
      id: 12,
      text: `إنتاج واختبار النسخ التجريبية قبل إطلاق الخدمة يساعد على فهم المستخدمين بدقة وبشكل افضل`,
      answer: true,
      img: priorities,
      userAnswer: null,
    },
    {
      id: 13,
      text: `تحديد الأولويات يعني: فرز وترتيب احتياجات المستفيدين وفق مصفوفة أولويات محورها المستفيد`,
      answer: true,
      img: priorities,
      userAnswer: null,
    },
    {
      id: 14,
      text: `تبدأ سلسلة قيمة تجربة المستفيدين بمرحلة "التصميم"`,
      answer: false,
      img: search,
      userAnswer: null,
    },
    {
      id: 15,
      text: `يركز النهج الذي يركز على المستفيدين على احتياجات وتفضيلات العمل`,
      answer: false,
      img: search,
      userAnswer: null,
    },
    {
      id: 16,
      text: `"تخطيط رحلة المستفيد" ليس له صلة بفهم نقاط الاتصال`,
      answer: false,
      img: search,
      userAnswer: null,
    },
    {
      id: 17,
      text: `"قياس تجربة المستفيدين" يتعلق فقط باستبيانات آراء المستفيدين`,
      answer: true,
      img: search,
      userAnswer: null,
    },
    {
      id: 18,
      text: `تبدأ سلسلة قيمة تجربة المستفيدين بمرحلة "التصميم"`,
      answer: true,
      img: search,
      userAnswer: null,
    },
    {
      id: 19,
      text: `تحسين ورفع كفاءة تجربة المستفيدين يُعنى بتطوير قنوات الخدمة الرقمية مثل فقط تطوير المنصات الالكترونية والتطبيقات`,
      answer: false,
      img: design,
      userAnswer: null,
    },
    {
      id: 20,
      text: `يركز تصميم تجربة المستفيد فقط على إرضاء المستفيد، وليس تجاوز توقعاتهم`,
      answer: false,
      img: design,
      userAnswer: null,
    },
    {
      id: 21,
      text: `تبدأ سلسلة قيمة تجربة المستفيد بمرحلة "التصميم"`,
      answer: false,
      img: design,
      userAnswer: null,
    },
    {
      id: 22,
      text: `"التخصيص" في تجربة المستفيد يعني تقديم نفس التجربة تمامًا لجميع المستفيدين`,
      answer: false,
      img: design,
      userAnswer: null,
    },
    {
      id: 23,
      text: `"التصميم المرتكز على المستفيد" يركز فقط على جماليات واجهات البرامج والخدمات`,
      answer: false,
      img: design,
      userAnswer: null,
    },
    {
      id: 24,
      text: `"التصميم" هو عملية تتم لمرة واحدة ولا تحتاج إلى تعديلات`,
      answer: false,
      img: design,
      userAnswer: null,
    },
    {
      id: 25,
      text: `تصميم الخدمة في الخدمات الحكومية لا يتطلب مشاركة المستخدم`,
      answer: false,
      img: design,
      userAnswer: null,
    },
    {
      id: 26,
      text: `يعد "تصميم تجربة المستفيد" جزءًا أساسيًا من سلسلة قيمة تجربة المستفيد.`,
      answer: true,
      img: design,
      userAnswer: null,
    },
    {
      id: 27,
      text: `تدريب الموظفين واشراكهم في تطوير الخدمات هي خطوة غير مهمة في تنفيذ تحسين تجربة المستفيد ورفع درجة الرضا عن الخدمات`,
      answer: false,
      img: tamkeen,
      userAnswer: null,
    },
    {
      id: 28,
      text: `تقتصر مبادئ تجربة المستفيد على تفاعلات المستفيد، وليس لتفاعلات الموظفين تأثير على نجاح تجربة المستفيد `,
      answer: false,
      img: tamkeen,
      userAnswer: null,
    },
    {
      id: 29,
      text: `يمكن أن تكون "استراتيجية تجربة المستفيد" ناجحة دون إشراك الموظفين في تنفيذها`,
      answer: false,
      img: tamkeen,
      userAnswer: null,
    },
    {
      id: 30,
      text: `تمكين وتدريب وتهيئة الموظفين يعد ممارسة أساسية وهامة في تصميم التجارب والخدمات بمنهجية التمركز حول المستفيد`,
      answer: true,
      img: tamkeen,
      userAnswer: null,
    },
    {
      id: 31,
      text: `التحسين المستمر هو أحد المبادئ الأساسية لمبادئ تجربة المستفيد.`,
      answer: true,
      img: tamkeen,
      userAnswer: null,
    },
    {
      id: 32,
      text: `مشاركة المستفيدين في تصميم وتقيم جودة الخدمات الحكومية ليس جانبِا مهمًا عند تطوير الخدمات الحكومية وليس جانبًا محوريًا في استراتيجية تجربة المستفيد`,
      answer: false,
      img: measurements,
      userAnswer: null,
    },
    {
      id: 33,
      text: `في تجربة المستفيد، "جمع ملاحظات المستفيد" هو نشاط لمرة واحدة، يتم فقط بعد تجربة المستفيد للخدمة`,
      answer: false,
      img: measurements,
      userAnswer: null,
    },
    {
      id: 34,
      text: `"قياس تجربة المستفيد" يتعلق فقط باستبيانات آراء المستفيدين`,
      answer: false,
      img: measurements,
      userAnswer: null,
    },
    {
      id: 35,
      text: `سلسلة القيمة في الخدمات الحكومية التي لا تقدمها الوزارة لا تتضمن مرحلة للمراقبة والتقييم`,
      answer: false,
      img: measurements,
      userAnswer: null,
    },
    {
      id: 36,
      text: `لا يمكن قياس آراء ومشاعر المستفيدين إلا عبر الاستبيانات ونماذج الأسئلة بعد استخدام الخدمة`,
      answer: false,
      img: measurements,
      userAnswer: null,
    },
    {
      id: 37,
      text: `تعليقات المستفيد ليست ضرورية لإجراء تحسينات في التجربة والخدمات`,
      answer: false,
      img: measurements,
      userAnswer: null,
    },
    {
      id: 38,
      text: `تتضمن "استراتيجية تجربة المستفيد" التركيز فقط على رضا المستفيد على المدى القصير.`,
      answer: false,
      img: measurements,
      userAnswer: null,
    },
    {
      id: 39,
      text: `مرحلة التطوير المستمر والمراقبة بعد اطلاق الخدمات تضمن استرارية رضا المستفيد ورفع درجة الرضا وكفاءة الخدمة بشكل مستمر`,
      answer: true,
      img: culture,
      userAnswer: null,
    },
    {
      id: 40,
      text: `تحسين تجربة المستفيد هي عملية لمرة واحدة فقط ولا تحتاج إلى متابعة او تطوير إضافي بعد نجاحها في اول مرة`,
      answer: false,
      img: culture,
      userAnswer: null,
    },
    {
      id: 41,
      text: `تحسين تجربة المستفيد في الخدمات الحكومية ليس ذو اهمية لان المستفيد ملزم باستخدام الخدمة ولا يمتلك خيارًا بديًلا`,
      answer: false,
      img: culture,
      userAnswer: null,
    },
    {
      id: 42,
      text: `تقديم مستوى متسق، وتجربة مترابطة عبر نقاط الاتصال المختلفة مع المستفيد ليس أمرًا بالغ الأهمية في نهج تجربة المستفيد`,
      answer: false,
      img: culture,
      userAnswer: null,
    },
    {
      id: 43,
      text: `تَسهيل الوصول إلى خدمات الأجهزة الحكومية ورفع كفاءتها وفعاليتها، ورفع درجة رضا المستفيدين عبر استراتجية التمركز حول المستفيد، يساهم في تحقيق رؤية 2030 بشكل مباشر`,
      answer: true,
      img: culture,
      userAnswer: null,
    },
    {
      id: 44,
      text: `تساعد استراتيجية تجربة المستفيد على تحفيز تنافس الجهات الحكومية مع القطاع الخاص ، مما يساهم بشكل مباشر على رفع جودة الخدمات ورفع درجة رضا المستفيدين`,
      answer: true,
      img: culture,
      userAnswer: null,
    },
    {
      id: 45,
      text: `تخفيض التكاليف هو الهدف الرئيسي من نهج تصميم التجارب والخدمات المتمركزة حول المستفيد، وليس رفع درجة رضا المستفيدين`,
      answer: false,
      img: priorities,
      userAnswer: null,
    },
    {
      id: 46,
      text: `يمكن تنفيذ استراتيجية وممارسات تجربة المستفيد مباشرة بدون تقييم حجم العمل والأثر المتوقع`,
      answer: false,
      img: priorities,
      userAnswer: null,
    },
    {
      id: 47,
      text: `تتضمن "استراتيجية تجربة المستفيد" تحديد الأهداف دون قياس حجم أثرها`,
      answer: false,
      img: priorities,
      userAnswer: null,
    },
    {
      id: 48,
      text: `تتضمن "استراتيجية تجربة المستفيد" تحديد أهداف غامضة دون اتخاذ إجراءات واضحة`,
      answer: false,
      img: priorities,
      userAnswer: null,
    },
    {
      id: 49,
      text: `"استراتيجية تجربة المستفيد" و"تصميم تجربة المستفيد" مصطلحان مترادفان`,
      answer: false,
      img: misunderstanding,
      userAnswer: null,
    },
    {
      id: 50,
      text: `الاتساق في تجربة المستفيد يعني تقديم نفس التجربة من خلال جميع نقاط الاتصال`,
      answer: false,
      img: misunderstanding,
      userAnswer: null,
    },
    {
      id: 51,
      text: `تجربة المستفيد هي نتيجة تفاعل المستفيد مع الوزارة وخدماتها، مما ينعكس على شعور ورضا المستفيد عن جودة وفعالية هذه التجربة`,
      answer: true,
      img: misunderstanding,
      userAnswer: null,
    },
    {
      id: 52,
      text: `النهج الذي يركز على المستفيد يأخذ في الاعتبار رحلة المستفيد بأكملها بدءًا من عمليات البحث والاستقصاء وحتى لحظة تقيم المستفيد للخدمة بعد التجربة`,
      answer: true,
      img: misunderstanding,
      userAnswer: null,
    },
    {
      id: 53,
      text: `تتوافق استراتيجية تجربة المستفيد مع أهداف الوزارة`,
      answer: true,
      img: misunderstanding,
      userAnswer: null,
    },
  ]);

  const navigate = useNavigate();

  const end = () => {
    localStorage.removeItem("quiz-1");
    navigate("/select-game");
  };

  return (
    <div className={styles.container}>
      <Header onEnd={end} />
      {/* <Timer /> */}
      <Quiz questionsData={questions} />
    </div>
  );
};

export default GameOne;
