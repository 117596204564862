import React from "react";
import styles from "./Quiz.module.css";

const ChoiceCard = ({ choice, selected, onClick, onTouchStart, incorrect }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(choice);
    }
  };

  const handleTouchStart = () => {
    if (onTouchStart) {
      onTouchStart(choice);
    }
  };

  return (
    <div
      className={`${styles.choiceCard} ${selected ? styles.selected : ""} ${incorrect ? styles.incorrect : ""}`}
      onClick={handleClick} // Handle click event for non-touch devices
      onTouchStart={handleTouchStart} // Handle touch event for touch devices
    >
      <p>{choice.option}</p>
    </div>
  );
};

export default ChoiceCard;
