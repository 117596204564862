import React from "react";
import styles from "./Splash.module.css";
import splashImage from "../assets/new/splash.svg";
import { useNavigate } from "react-router-dom";

const Splash = () => {
  const navigate = useNavigate();
  const start = () => {
    navigate("/signup");
  };
  return (
    <div className={styles.container} onClick={start}>
      <img className={styles.img} src={splashImage} />
      {/* <h1>اضغط على الشاشة للبدء</h1> */}
    </div>
  );
};

export default Splash;
